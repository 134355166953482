import React, { Component } from 'react'

class Footer extends Component {

    render() {
        return (
            <div class="footer">
                <div>Copyleft</div>
            </div>
        )
    }
}

export default Footer
